import React from 'react';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import { useFeaturePdpEnhancements } from 'hooks/unleash/useFeaturePdpEnhancements';

import css from 'styles/components/common/finalSale.scss';

interface Props {
  className?: string;
}
export const FinalSale = ({ className }: Props) => {
  const {
    testId,
    marketplace: { dataMaskPiiElements }
  } = useMartyContext();
  const { isTreatmentPricingEnabled } = useFeaturePdpEnhancements();

  return (
    <em
      className={cn(isTreatmentPricingEnabled ? css.finalSalePdpEnhancements : css.container, className)}
      data-test-id={testId('finalSale')}
      data-cs-mask={dataMaskPiiElements}
    >
      Final Sale - No Returns
    </em>
  );
};

export default FinalSale;
